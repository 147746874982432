//Chakra UI
import {
  Box,
  ChakraProvider,
  Flex,
  Image,
  Text,
  useBreakpointValue
} from '@chakra-ui/react'

import Fonts from '../components/base/fonts'
import { Logo } from "../images/Logo"
//React
import React from "react"
//Components
import Svg from '../images/statys-background-animation-large.svg'
import { theme }  from '../theme/theme'

const ComingSoon = () => {

  const isMobile = useBreakpointValue({ base: true, xl: false })

  return (
    <ChakraProvider theme={theme}>
      <Fonts/> 
          <Box height="100vh">
              {!isMobile && (<Image src={Svg} style={{ position: `absolute`, left: `0`, height: `-webkit-fill-available` }}></Image>)}
              
            <Flex flexDirection="column" alignItems="center" justifyContent="center" height="inherit">
                <Logo height="100px" fill="blue.600"></Logo>
                <Text fontSize="60px" color="blue.600" pt="32px"> Coming soon </Text>
        </Flex>
          </Box>
     </ChakraProvider>
  )
}

export default ComingSoon
